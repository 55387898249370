import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import vSelect from 'vue-select'
//import route from 'vue-router';
import axios from "axios";
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin,
        vSelect,
        axios
    },
    data: function() {
        return {
            pageTitle: "Bank transfert > ",//route.params.id
            moreParams: {},
            base_url: Vue.BASE_URL,
            job_id: "", //this.$route.query.id,
            employee_info:"",
            emp_ename:"",
            last_ename:"",
            Position:"",
            compte_donneurs:[],
            employee_complete_informations:[],
            compte_beneficiaires:[],
            saveText: "Save the transfer order",
            crudform: {
                job_id: "",
                nom_donneur: "",
                adresse_donneur: "",
                compte_a_debiter: "MGA",
                num_compte_donneur: "",
                montant_virement: "",
                code_devise: "MGA",
                modalite_de_paiement_frais: "OUR",
                nom_beneficiaire: "",
                adresse_beneficiaire: "",
                nom_banque_beneficiaire: "",
                adresse_banque_beneficiaire: "",
                code_bic_swift: "",
                iban: "",
                num_compte_beneficiaire: "",
                motif_virement: "ECONOMIE SUR SALAIRE",
                dj_facture: "",
                dj_certificat_assurance: "",
                dj_bl_lta_awb: "",
                dj_dau: "",
                dj_note_de_fret: "",
                no_domiciliation: "",
                dj_fiche_declaration: "",
                dj_autre: "",
                id:""
            },
            fields: [
                {
                    name: 'id_formatted',
                    title: 'ID',
                    sortField: 'id',
                    dataClass: 'text-center'
                },
                {
                    name: 'date_enregsitrement_formatted',
                    title: 'Date',
                    sortField: 'date_enregsitrement',
                    dataClass: 'text-center'
                },
                {
                    name: 'nom_donneur',
                    title: 'Employee name',
                    sortField: 'nom_donneur'
                },
                {
                    name: 'montant_virement',
                    title: 'Amount',
                    dataClass: 'text-right',
                    sortField: 'last_ename',
                    width: "120px"
                },
                {
                    name: 'nom_beneficiaire',
                    title: 'Name of beneficiary',
                    sortField: 'nom_beneficiaire'
                },
                {
                    name: 'nom_banque_beneficiaire',
                    title: 'Bank name of beneficiary',
                    sortField: 'nom_banque_beneficiaire'
                },
                {
                    name: 'actions',
                    width: "120px",
                    dataClass: "text-center"
                }

            ],
            sortOrder: [
                { field: 'id', direction: 'desc' }
            ],
            css: {
                table: {
                    tableClass: 'table table-striped table-bordered table-hovered',
                    loadingClass: 'loading',
                    ascendingIcon: 'glyphicon glyphicon-chevron-up',
                    descendingIcon: 'glyphicon glyphicon-chevron-down',
                    handleIcon: 'glyphicon glyphicon-menu-hamburger',
                },
                pagination: {
                    infoClass: 'pull-left ',
                    wrapperClass: 'vuetable-pagination text-center',
                    activeClass: 'btn-secondary',
                    disabledClass: 'disabled',
                    pageClass: 'btn btn-border',
                    linkClass: 'btn btn-border',
                    icons: {
                        first: '',
                        prev: '',
                        next: '',
                        last: '',
                    },
                },
            },
            motCle: "",
            tabledata: [],
            json_fields: {
                'Job ID': 'job_id',
                'Ambatovy ID': 'Ambatovy_ID',
                'Name': 'emp_ename',
                'Last Name': 'last_ename',
                'Position': 'Position',
                'Remarks': 'Remarks',
                'Department': 'Department',
            },
            json_data: [],
            json_meta: [
                [{
                    'key': 'charset',
                    'value': 'utf-8'
                }]
            ],

        }
    },
    methods: {
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        editRow(rowData) {
            //alert("You clicked edit on" + JSON.stringify(rowData))
            this.crudform = rowData;
            console.log(rowData);
            //console.log(rowData.montant_virement);
            //this.crudform.montant_virement = (rowData.montant_virement).replace(".", ",");
            this.saveText= "Save change on the transfer order";
            $('#profile-tab').trigger('click');
        },
        deleteRow(rowData) {
            //alert("You clicked delete on" + JSON.stringify(rowData))

            var that =  this;
            this.$bvModal.msgBoxConfirm('Are you sure you want to delete this row?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                console.log(value);
                if(value == true) {
                    axios.post(that.base_url+"/ordredevirement/delete/"+rowData.id, $("#formulaire").serialize()).then(function(response) {
                        console.log(response);
                        //this.message_reponse = response.data.msg;
                        if (response.data.error === "") {
                            console.log(response.data.message);
                            that.items = [];
                            that.setFilter();
                            Vue.$toast.open({
                                message: response.data.message,
                                type: 'success',
                                position: 'top-right'
                                // all of other options may go here
                            });
                        } else {
                            console.log("Erreur");
                            Vue.$toast.open({
                                message: response.data.error,
                                type: 'error',
                                position: 'top-right',
                                duration: 0,
                                // all of other options may go here
                            });
                        }
                    });
                }
            })
            .catch(err => {
                console.log(err);
                // An error occurred
            })

        },
        onLoading() {
            console.log('loading... show your spinner here')
            
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        setFilter() {
            this.moreParams.filter = this.motCle;
            this.moreParams.criteriacol = this.criteriacol;
            this.moreParams.job_id = this.job_id;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.moreParams.filter = this.motCle;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        resetForm() {
            this.crudform = {
                job_id: "",
                nom_donneur: "",
                adresse_donneur: "",
                compte_a_debiter: "MGA",
                num_compte_donneur: "",
                montant_virement: "",
                code_devise: "MGA",
                modalite_de_paiement_frais: "OUR",
                nom_beneficiaire: "",
                adresse_beneficiaire: "",
                nom_banque_beneficiaire: "",
                adresse_banque_beneficiaire: "",
                code_bic_swift: "",
                iban: "",
                num_compte_beneficiaire: "",
                motif_virement: "ECONOMIE SUR SALAIRE",
                dj_facture: "",
                dj_certificat_assurance: "",
                dj_bl_lta_awb: "",
                dj_dau: "",
                dj_note_de_fret: "",
                no_domiciliation: "",
                dj_fiche_declaration: "",
                dj_autre: "",
                id:""
            };
            var that = this;
            this.job_id = this.$route.params.jobid
            axios.post(this.base_url+"/employeeall/fetchone/"+this.job_id).then(function(response) {
                that.employee_info = response.data[0];
                that.employee_complete_informations = response.data;
                //console.log("------");
                //console.log(response.data[0]);
                that.crudform.nom_donneur = that.employee_info.fullName;
                that.crudform.adresse_donneur = that.employee_info.mada_address;
                that.$emit('change-page', that.pageTitle + that.employee_info.emp_ename + " " + that.employee_info.last_ename + " (" + that.employee_info.Position + ")");
            });
            this.saveText= "Save the transfer order";
        },
        loadCompteEmployee() {
            var that = this;
            axios.post(this.base_url+"/ordredevirement/fetchcompte/"+this.job_id).then(function(response) {
                //console.log(response.data);
                that.compte_beneficiaires = response.data.compte_beneficiaire;
                that.compte_donneurs = response.data.compte_donneur;

                if(that.compte_beneficiaires == "") {
                    that.compte_beneficiaires = [{
                        adresse_banque_beneficiaire: "",
                        adresse_beneficiaire: "",
                        code_bic_swift: "",
                        iban: "",
                        job_id: "",
                        nom_banque_beneficiaire: "",
                        nom_beneficiaire: that.crudform.nom_donneur,
                        num_compte_beneficiaire: ""
                    }];
                }
                if(that.compte_donneurs == "") {
                    that.compte_donneurs = [{
                        job_id: "",
                        num_compte_donneur: ""
                    }];
                }
                //console.log(that.compte_beneficiaires);
                console.log(that.compte_donneurs);
            });
        },
        onExport() {

            this.tabledata = this.$refs.vuetable.$data.tableData;
            this.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        onSubmit() {
            var that =  this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                title: 'Confirmation',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'Continuer',
                cancelTitle: 'Annuler',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                console.log(value);
                if(value == true) {
                    axios.post(this.base_url+"/ordredevirement/action", $("#formulaire").serialize()).then(function(response) {
                        console.log(response);
                        //this.message_reponse = response.data.msg;
                        if (response.data.error === "") {
                            console.log("Enregistrement effectué avec succès!");
                            // that.closeModal();
                            that.setFilter();
                            Vue.$toast.open({
                                message: 'Enregistrement effectué avec succès!',
                                type: 'success',
                                position: 'top-right'
                                // all of other options may go here
                            });
                            that.resetForm();
                        } else {
                            console.log("Erreur");
                            Vue.$toast.open({
                                message: response.data.error,
                                type: 'error',
                                position: 'top-right',
                                duration: 0,
                                // all of other options may go here
                            });
                        }
                    });
                }
            })
            .catch(err => {
                console.log(err);
                // An error occurred
            })
            
        },
        nom_beneficiaireOnChange(item) {
            console.log(item);
            
            this.crudform.adresse_beneficiaire = "";
            this.crudform.nom_banque_beneficiaire = "";
            this.crudform.num_compte_beneficiaire = "";
            this.crudform.adresse_banque_beneficiaire = "";
            this.crudform.code_bic_swift = "";
            this.crudform.iban = "";
            
            if(this.compte_beneficiaires.length > 0) {
                const result = this.compte_beneficiaires.find( ({ nom_beneficiaire }) => nom_beneficiaire === item );

                if(result != "undefined") {
                    this.crudform.nom_beneficiaire = result.nom_beneficiaire;
                    this.crudform.adresse_beneficiaire = result.adresse_beneficiaire;
                    this.crudform.nom_banque_beneficiaire = result.nom_banque_beneficiaire;
                    this.crudform.num_compte_beneficiaire = result.num_compte_beneficiaire;
                    this.crudform.adresse_banque_beneficiaire = result.adresse_banque_beneficiaire;
                    this.crudform.code_bic_swift = result.code_bic_swift;
                    this.crudform.iban = result.iban;
                }
                console.log(result);
            }
            else {
                
                this.crudform.nom_beneficiaire = item;
            }
        },
        nom_donneurOnChange(item) {
            console.log(item);
            this.crudform.nom_donneur = item;
        },
        num_compte_donneurOnChange(item) {
            console.log(item);
            this.crudform.num_compte_donneur = item;
        },
        nom_banque_beneficiaireOnChange(item) {
            //console.log("--------------");
            console.log(item);
            //this.crudform.nom_banque_beneficiaire = item;
            //this.crudform.compte_beneficiaires['nom_banque_beneficiaire'] = item;

            this.crudform.nom_banque_beneficiaire = item;
            this.crudform.num_compte_beneficiaire = "";
            this.crudform.adresse_banque_beneficiaire = "";
            this.crudform.code_bic_swift = "";
            this.crudform.iban = "";
            
            if(this.compte_beneficiaires.length > 0) {
                const result = this.compte_beneficiaires.find( ({ nom_banque_beneficiaire }) => nom_banque_beneficiaire === item );
                console.log(result);
                if(result != "undefined") {
                    this.crudform.nom_banque_beneficiaire = result.nom_banque_beneficiaire;
                    this.crudform.num_compte_beneficiaire = result.num_compte_beneficiaire;
                    this.crudform.adresse_banque_beneficiaire = result.adresse_banque_beneficiaire;
                    this.crudform.code_bic_swift = result.code_bic_swift;
                    this.crudform.iban = result.iban;
                }
            } else {
                this.crudform.nom_banque_beneficiaire = item;
            }
            
            
        },
    },
    computed: {
        /*httpOptions(){
            return {headers: {'Authorization': "my-token"}} //table props -> :http-options="httpOptions"
        },*/
    },
    created: function() {
        
        this.motCle = "";
        this.criteriacol = "";

        var that = this;
        this.job_id = this.$route.params.jobid
        axios.post(this.base_url+"/employeeall/fetchone/"+this.job_id).then(function(response) {
            that.employee_info = response.data[0];
            that.employee_complete_informations = response.data;
            //console.log("------");
            //console.log(response.data[0]);
            that.crudform.nom_donneur = that.employee_info.fullName;
            that.crudform.adresse_donneur = that.employee_info.mada_address;
            that.$emit('change-page', that.pageTitle + that.employee_info.emp_ename + " " + that.employee_info.last_ename + " (" + that.employee_info.Position + ")");
        });

        this.loadCompteEmployee();
        
        

        
    },
    mounted: function() {
        // var that = this;

        //console.log(this.$route.params);

    }
}